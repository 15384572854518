import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APAccordion from "../../../components/APAccordion";
import APAutoComplete from "../../../components/form/APAutoComplete";
import APRadioGroup from "../../../components/form/APRadioGroup";
import APTextField from "../../../components/form/APTextField";
import {
  showResidenceLandlordFields,
  showResidencePreviousAddress,
} from "../../../helpers/financeApplication";
import { AddressResidenceStatus } from "../../../types/Dossier";
import { stateOptions } from "../../../types/State";
import { FinanceApplicationInputs } from "..";

export const residenceOptions: {
  id: AddressResidenceStatus;
  label: string;
}[] = [
  { id: "own-outright", label: "Own - Outright" },
  { id: "own-mortgage", label: "Own - Mortgage" },
  { id: "rent", label: "Rent" },
  { id: "boarding", label: "Boarding" },
  { id: "live-with-relatives", label: "Live with Relatives" },
];

const FinanceApplicationResidenceForm = ({
  control,
  errors,
  watch,
  setValue,
  readOnly,
  highlightRequired,
}: {
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const watchResidence = watch("currentResidenceStatus");
  const watchYears = watch("currentYearsAt");
  const showLandlordFields = showResidenceLandlordFields(watchResidence);
  const showPreviousResidence = showResidencePreviousAddress(watchYears);

  // If user selects a residence status other then rent then
  // clear the landlord fields
  useEffect(() => {
    if (!showLandlordFields) {
      setValue("currentLandlord", "");
      setValue("currentLandlordPhone", "");
    }
  }, [watchResidence]);

  // If user has been at their current residence for less then
  // greater then two years, then clear previous residence fields
  useEffect(() => {
    if (!showPreviousResidence) {
      setValue("previousStreetAddress", "");
      setValue("previousSuburb", "");
      setValue("previousState", null);
      setValue("previousPostcode", "");
      setValue("previousYearsAt", "");
      setValue("previousMonthsAt", "");
    }
  }, [showPreviousResidence]);

  return (
    <Stack spacing={2}>
      <APTextField<FinanceApplicationInputs>
        name="currentStreetAddress"
        label="Residential Street Address"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        placeholder="Unit / House Number Street"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APTextField<FinanceApplicationInputs>
        name="currentSuburb"
        label="Suburb"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APAutoComplete
        name="currentState"
        label="State"
        options={stateOptions}
        control={control}
        validations={{}}
        errors={errors}
        defaultValue={null}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APTextField<FinanceApplicationInputs>
        name="currentPostcode"
        label="Postcode"
        control={control}
        validations={{
          maxLength: 4,
          formatValidation: /^[0-9\s()+-]+$/,
        }}
        errors={errors}
        defaultValue=""
        placeholder="xxxx"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APRadioGroup
        name="currentResidenceStatus"
        label="Residence Status"
        options={residenceOptions}
        control={control}
        validations={{}}
        errors={errors}
        defaultValue={""}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      {showLandlordFields && (
        <Stack spacing={2}>
          <APTextField<FinanceApplicationInputs>
            name="currentLandlord"
            label="Current Landlord/Agent"
            control={control}
            validations={{
              maxLength: 100,
            }}
            errors={errors}
            defaultValue=""
            placeholder="Name"
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />

          <APTextField<FinanceApplicationInputs>
            name="currentLandlordPhone"
            label="Landlord/Agent Phone"
            control={control}
            validations={{
              minLength: 8,
              maxLength: 20,
              formatValidation: /^[0-9\s()+-]+$/,
            }}
            errors={errors}
            defaultValue=""
            placeholder="Phone"
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        </Stack>
      )}

      <Box
        sx={{ display: "flex", justifyContent: "space-between", columnGap: 2 }}
      >
        <APTextField<FinanceApplicationInputs>
          name="currentYearsAt"
          label="Yrs at Address"
          control={control}
          validations={{
            maxLength: 2,
            formatValidation: /^[0-9\s()+-]+$/,
          }}
          errors={errors}
          defaultValue=""
          showRequired={true}
          highlightOnEmpty={highlightRequired}
          readOnly={readOnly}
        />
        <APTextField<FinanceApplicationInputs>
          name="currentMonthsAt"
          label="Mths at Address"
          control={control}
          validations={{
            maxLength: 2,
            formatValidation: /^([0-9]|10|11)$/,
          }}
          errors={errors}
          defaultValue=""
          readOnly={readOnly}
        />
      </Box>

      {showPreviousResidence && (
        <Box sx={{ pt: 2 }}>
          <APAccordion
            title="Previous Residential Address"
            defaultExpanded={true}
          >
            <Stack spacing={2} sx={{ pt: 2 }}>
              <APTextField<FinanceApplicationInputs>
                name="previousStreetAddress"
                label="Previous Street Address"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                placeholder="Unit / House Number Street"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APTextField<FinanceApplicationInputs>
                name="previousSuburb"
                label="Previous Suburb"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APAutoComplete
                name="previousState"
                label="Previous State"
                options={stateOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APTextField<FinanceApplicationInputs>
                name="previousPostcode"
                label="Previous Postcode"
                control={control}
                validations={{
                  maxLength: 4,
                  formatValidation: /^[0-9\s()+-]+$/,
                }}
                errors={errors}
                defaultValue=""
                placeholder="xxxx"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 2,
                }}
              >
                <APTextField<FinanceApplicationInputs>
                  name="previousYearsAt"
                  label="Yrs at Prev Address"
                  control={control}
                  validations={{
                    maxLength: 2,
                    formatValidation: /^[0-9\s()+-]+$/,
                  }}
                  errors={errors}
                  defaultValue=""
                  showRequired={true}
                  highlightOnEmpty={highlightRequired}
                  readOnly={readOnly}
                />
                <APTextField<FinanceApplicationInputs>
                  name="previousMonthsAt"
                  label="Mths at Prev Address"
                  control={control}
                  validations={{
                    maxLength: 2,
                    formatValidation: /^([0-9]|10|11)$/,
                  }}
                  errors={errors}
                  defaultValue=""
                  readOnly={readOnly}
                />
              </Box>
            </Stack>
          </APAccordion>
        </Box>
      )}
    </Stack>
  );
};

export default FinanceApplicationResidenceForm;
