import Box from "@mui/material/Box";
import { Carousel } from "react-responsive-carousel";

interface DossierStatusStepperProps {
  vehicleImages: string[];
}

const VehicleImageCarousel = ({ vehicleImages }: DossierStatusStepperProps) => {
  return (
    <Carousel
      autoPlay={false}
      centerMode={true}
      centerSlidePercentage={80}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      infiniteLoop={false}
      renderThumbs={() => {
        return vehicleImages.map((image, i) => (
          <Box
            key={i}
            component="img"
            src={image}
            sx={{
              display: "flex",
            }}
          ></Box>
        ));
      }}
    >
      {vehicleImages.map((image, i) => (
        <Box key={i} sx={{ pr: "10%" }}>
          <Box
            component="img"
            src={image}
            sx={{
              display: "flex",
              maxHeight: "30rem",
            }}
          ></Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default VehicleImageCarousel;
