import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateValidationError } from "@mui/x-date-pickers/models/validation";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";

interface APDateFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  errors: FieldErrors<T>;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minDateErrorMessage?: string;
  maxDateErrorMessage?: string;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
}

const APDateField = <T extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  errors,
  setError,
  clearErrors,
  minDate,
  maxDate,
  maxDateErrorMessage,
  showRequired,
  highlightOnEmpty,
  readOnly,
}: APDateFieldProps<T>) => {
  const [error, setDateError] = useState<DateValidationError | null>(null);
  const formError = getNestedProperty(name, errors);

  const getErrorMessage = (error: DateValidationError) => {
    let message = null;
    switch (error) {
      case "maxDate":
        if (maxDateErrorMessage) {
          message = maxDateErrorMessage;
        }
        break;
      default:
        break;
    }

    return message;
  };

  useEffect(() => {
    if (error) {
      setError(name, {
        type: "string",
        message: getErrorMessage(error) || "",
      });
    } else {
      clearErrors(name);
    }
  }, [error]);

  return (
    <Controller<T>
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ validate: () => !error }}
      render={({ field }) => (
        <DatePicker
          {...field}
          format="DD/MM/YYYY"
          label={showRequired ? `${label} *` : label}
          minDate={minDate}
          maxDate={maxDate}
          onError={(newError) => setDateError(newError)}
          readOnly={readOnly}
          slotProps={{
            textField: {
              helperText: `${formError ? formError?.message : ""}`,
              error: !!formError || (highlightOnEmpty && !field.value),
            },
          }}
        />
      )}
    />
  );
};

export default APDateField;
