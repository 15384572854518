import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";
import {
  ValidationOptions,
  validationOptionsToControlRules,
} from "../../types/Validations";

export type AutoCompleteOption = {
  label: string;
  id: string;
};

interface APAutoCompleteProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: AutoCompleteOption[];
  validations: ValidationOptions;
  errors: FieldErrors<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hiddenOptions?: string[];
}

const APAutoComplete = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  validations,
  errors,
  defaultValue,
  showRequired,
  highlightOnEmpty,
  readOnly,
  disabled,
  hiddenOptions,
}: APAutoCompleteProps<T>) => {
  const error = getNestedProperty(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      rules={validationOptionsToControlRules(label, validations)}
      defaultValue={defaultValue}
      render={({ field }) => {
        if (options.length > 15) {
          return (
            <Autocomplete
              disablePortal
              freeSolo={false}
              multiple={false}
              id={name}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              options={options.filter(
                (option) =>
                  !hiddenOptions || hiddenOptions.indexOf(option.id) === -1
              )}
              readOnly={readOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={validations.required}
                  sx={{ backgroundColor: "white" }}
                  label={showRequired ? `${label} *` : label}
                  error={!!error || (highlightOnEmpty && !field.value)}
                  helperText={`${error?.message || ""}`}
                />
              )}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(event, value) => {
                field.onChange(value);
              }}
              disabled={disabled}
            />
          );
        } else {
          return (
            <FormControl
              id={name}
              fullWidth
              error={!!error || (highlightOnEmpty && !field.value)}
              disabled={disabled}
            >
              <InputLabel>{showRequired ? `${label} *` : label}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={field.value?.id || ""}
                label={showRequired ? `${label} *` : label}
                readOnly={readOnly}
                required={validations.required}
                onBlur={field.onBlur}
                sx={{ backgroundColor: "white" }}
                onChange={(event) => {
                  const id = event.target.value;
                  const option = options.find((option) => option.id === id);
                  field.onChange({ id, label: option?.label || "" });
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      display:
                        hiddenOptions && hiddenOptions.indexOf(option.id) !== -1
                          ? "none"
                          : "block",
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{`${error?.message || ""}`}</FormHelperText>
            </FormControl>
          );
        }
      }}
    />
  );
};

export default APAutoComplete;
