import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { formatDate } from "../../helpers/dates";
import { DossierStatus } from "../../types/Dossier";

const statusToColor: Record<DossierStatus, string> = {
  Active: "primary.main",
  New: "secondary.main",
  Closed: "grey.600",
  Closing: "grey.600",
  Cancelled: "grey.600",
};

const DossierCard = ({
  id,
  status,
  identity,
  startDate,
  endDate,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vehicleImage,
}: {
  id: string;
  status: DossierStatus;
  identity: string;
  startDate: string;
  endDate: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleImage: string;
}) => {
  const color = statusToColor[status];

  return (
    <Card sx={{ display: "flex" }} variant="outlined">
      <CardActionArea
        color="primary"
        sx={{
          display: "flex",
          backgroundColor: "surface.main",
        }}
        component={Link}
        to={`/dossiers/${id}`}
      >
        <Box
          sx={{
            display: "flex",
            p: 2,
            flexGrow: 1,
            flex: 2,
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: 2, alignItems: "center" }}>
            <Avatar
              sx={{
                backgroundColor: color,
                height: "3rem",
                width: "3rem",
              }}
            >
              <Typography variant="bodySmall" color="onPrimary.main">
                {status}
              </Typography>
            </Avatar>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="titleMedium" color="onSurface.main">
                {vehicleYear} {vehicleMake} {vehicleModel}
              </Typography>
              <Typography variant="bodyMedium" color="onSurface.main">
                {identity}{" "}
                {startDate &&
                  endDate &&
                  `${formatDate(startDate)} - ${formatDate(endDate)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CardMedia
          component="img"
          sx={{
            minWidth: "5rem",
            maxHeight: "8rem",
            flex: 1,
            maxWidth: "10rem",
          }}
          image={vehicleImage}
          alt={`${vehicleYear} ${vehicleMake} ${vehicleModel}`}
        />
      </CardActionArea>
    </Card>
  );
};

export default DossierCard;
