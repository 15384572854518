import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "react-query";

import { fetchFile } from "../api/autoPilot";

const APImageWithLoader = ({
  token,
  imageUrl,
  width,
  height,
}: {
  token: string;
  imageUrl: string;
  width: string;
  height: string;
}) => {
  const imageSrc = useQuery(["file", imageUrl], () =>
    fetchFile(token || "", imageUrl, true)
  );

  return (
    <Box position="relative" width={width} maxHeight={height}>
      {imageSrc.isLoading && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      {!imageSrc.isLoading && (
        <Box
          component="img"
          src={imageSrc.data}
          sx={{ maxWidth: width, maxHeight: height }}
        ></Box>
      )}
    </Box>
  );
};

export default APImageWithLoader;
