import { AutoCompleteOption } from "../components/form/APAutoComplete";

export function buildAutoCompleteOptions(
  items: Record<string, unknown>[],
  labelKey: string,
  idKey: string
): AutoCompleteOption[] {
  const options: AutoCompleteOption[] = [];

  items.forEach((item) => {
    options.push({ label: `${item[labelKey]}`, id: `${item[idKey]}` });
  });

  return options;
}

export function getAutoCompleteLabel(
  options: AutoCompleteOption[],
  optionId: string
) {
  const option = options.find((option) => option.id === optionId);

  return option ? option.label : "";
}
