import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APAutoComplete from "../../../components/form/APAutoComplete";
import APTextField from "../../../components/form/APTextField";
import { buildAutoCompleteOptions } from "../../../helpers/buildOptions";
import {
  hasAssetOrLiability,
  showAssetIncomeAndMortgage,
  showLiabilityFinancier,
} from "../../../helpers/financeApplication";
import { AssetAndLiabilityType } from "../../../types/Dossier";
import { FinanceApplicationInputs } from "..";

const AssetAndLiabilityCard = ({
  id,
  isNew,
  types,
  control,
  errors,
  watch,
  setValue,
  onRemove,
  readOnly,
  highlightRequired,
}: {
  id: number;
  isNew: boolean;
  types: AssetAndLiabilityType[];
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  onRemove: (id: number, isNew: boolean) => void;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const parentList = isNew ? "newAssetsAndLiabilities" : "assetsAndLiabilities";
  const watchType = watch(`${parentList}.${id}.type`);
  const watchLiabilityValue = watch(`${parentList}.${id}.liabilityValue`);
  const previousType = useRef(watchType);

  useEffect(() => {
    // If there is no type selected or the type has been changed then clear the asset and liablity fields
    if (
      !watchType ||
      (previousType.current && watchType.id !== previousType.current.id)
    ) {
      setValue(`${parentList}.${id}.assetValue`, "");
      setValue(`${parentList}.${id}.financier`, "");
      setValue(`${parentList}.${id}.liabilityValue`, "");
    }
    previousType.current = watchType;
  }, [watchType]);

  const [hasAsset, hasLiability] = watchType
    ? hasAssetOrLiability(types, watchType.id)
    : [false, false];
  const showFinancier = showLiabilityFinancier(watchLiabilityValue);
  const showIncomeAndMortgage = showAssetIncomeAndMortgage(watchType);
  const hiddenTypes = [] as string[];
  types.forEach((type) => {
    if (!type.isActive) {
      hiddenTypes.push(`${type.typeId}`);
    }
  });

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "surface.main",
        rowGap: 2,
        p: 2,
      }}
      variant="outlined"
    >
      <Box sx={{ display: "flex", flex: 1 }}>
        <Box sx={{ flex: 1, pr: 1 }}>
          <APTextField
            name={`${parentList}.${id}.description`}
            label="Description"
            control={control}
            validations={{
              maxLength: 100,
            }}
            errors={errors}
            defaultValue=""
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        </Box>
        {!readOnly && (
          <Box sx={{ display: "flex" }}>
            <IconButton
              sx={{ alignSelf: "center" }}
              size="large"
              aria-label="remove-asset-and-liability"
              color="onSurface"
              onClick={() => onRemove(id, isNew)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 2,
        }}
      >
        <Box sx={{ flex: 3 }}>
          <APAutoComplete
            name={`${parentList}.${id}.type`}
            label="Type"
            options={buildAutoCompleteOptions(types, "typeLabel", "typeId")}
            control={control}
            validations={{}}
            errors={errors}
            defaultValue={null}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
            hiddenOptions={hiddenTypes}
          />
        </Box>

        <Box sx={{ flex: 2 }}>
          {hasAsset && (
            <APTextField
              name={`${parentList}.${id}.assetValue`}
              label="Value"
              control={control}
              validations={{
                formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
              }}
              errors={errors}
              defaultValue=""
              startAdornment="$"
              placeholder="xxxxx.xx"
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
              formatNumber={true}
            />
          )}
        </Box>
      </Box>

      {hasLiability && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Box sx={{ flex: 2 }}>
            <APTextField
              name={`${parentList}.${id}.liabilityValue`}
              label="Owing"
              control={control}
              validations={{
                formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
              }}
              errors={errors}
              defaultValue=""
              startAdornment="$"
              placeholder="xxxxx.xx"
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
              formatNumber={true}
            />
          </Box>
          <Box sx={{ flex: 3 }}>
            {showFinancier && (
              <APTextField
                name={`${parentList}.${id}.financier`}
                label="Financier/Bank"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />
            )}
          </Box>
        </Box>
      )}

      {showIncomeAndMortgage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <APTextField
              name={`${parentList}.${id}.incomeMonthly`}
              label="Income (Mth)"
              control={control}
              validations={{
                formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
              }}
              errors={errors}
              defaultValue=""
              startAdornment="$"
              placeholder="xxxxx.xx"
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
              formatNumber={true}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <APTextField
              name={`${parentList}.${id}.mortgageMonthly`}
              label="Mortgage (Mth)"
              control={control}
              validations={{
                formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
              }}
              errors={errors}
              defaultValue=""
              startAdornment="$"
              placeholder="xxxxx.xx"
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
              formatNumber={true}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default AssetAndLiabilityCard;
