import querystring from "querystring";

import { Config } from "../../config";
import { DeepPartial } from "../../helpers/typescript";
import { BankAccount, Contact } from "../../types/Contact";
import {
  AssetAndLiability,
  AssetAndLiabilityType,
  DetailedDossierStatus,
  Dossier,
  EmployerUsesAutoUXOption,
  FinanceApplication,
} from "../../types/Dossier";
import {
  APFaqVoteType,
  APPage,
  Faq,
  FaqAppLocation,
  FaqVote,
} from "../../types/Faq";
import {
  CalculatedQuote,
  Quote,
  QuotePayPeriod,
  QuotesSyncEvent,
} from "../../types/Quote";
import { PayrollCycle, User } from "../../types/User";
import {
  VehicleMake,
  VehicleModel,
  VehicleModelYear,
  VehicleOptions,
  VehicleVariant,
} from "../../types/Vehicles";

const REQUEST_DETAILS_HEADER = "Request-Details";

type RequestEmployerSetupPayload = {
  employerName: string;
  employerUsesAutoUX: EmployerUsesAutoUXOption;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  mobile?: string;
};

type QualifyLeadPayload = {
  mobilePhone: string;
  companyName: string;
  leadSourceCode?: string;
};

type QualifyLeadRecoverPayload = {
  catchEDriverExists: boolean;
  initalSetupCompleted: boolean;
};

type CreateQuotePayload = {
  dossierId: string;
  state: string;
  variantId: string;
  term: number;
  annualKilometres: number;
  salary: number;
  businessUsage: number;
  listPrice?: number;
};

type CreateBankAccountPayload = {
  financialInstitution: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
  driverIp: string;
  driverDevice: string;
  dossierId?: string;
};

type CreateFaqViewPayload = {
  appLocationId: string | null;
  faqAppLocationId: string | null;
};

type CreateFaqVotePayload = {
  vote: APFaqVoteType | null;
  appLocationId: string | null;
  faqAppLocationId: string | null;
};

export type UpdateQuotePayload = {
  name?: string;
  payPeriod?: QuotePayPeriod;
  state?: string;
  variantId?: string;
  term?: number;
  annualKilometres?: number;
  salary?: number;
  businessUsage?: number;
  listPrice?: number;
  optionalEquipment?: string[];
};

export type FinanceApplicationFileUploads = {
  driversLicenseFrontImage?: File;
  driversLicenseBackImage?: File;
  lastTaxReturnImage?: File;
  prevTaxReturnImage?: File;
  latestPayslipImage?: File;
  prevPayslipImage?: File;
  spouseLastTaxReturnImage?: File;
  spousePrevTaxReturnImage?: File;
  spouseLatestPayslipImage?: File;
  spouseIncomeDeclarationImage?: File;
};

export type AssetsAndLiabilitiesUpdatePayload = {
  add?: Omit<AssetAndLiability, "id" | "financeId" | "name" | "typeLabel">[];
  remove?: string[];
  update?: (Pick<AssetAndLiability, "id"> &
    Partial<
      Omit<AssetAndLiability, "id" | "financeId" | "name" | "typeLabel">
    >)[];
};

export type UpdateFinanceApplicationPayload = DeepPartial<
  Omit<FinanceApplication, "assetsAndLiabilities">
> & {
  fileUploads?: FinanceApplicationFileUploads;
  assetsAndLiabilities?: AssetsAndLiabilitiesUpdatePayload;
};

export type ApproveDriverPayload = {
  employerIp: string;
  employerDevice: string;
  employerId?: string;
  payrollCycleId?: string;
  employeeId?: string;
};

export type ClientIPData = {
  ip: string;
};

function attachQueryString(path: string, queryString: string | null) {
  return queryString ? `${path}?${queryString}` : path;
}

async function handleErrorResponse(response: Response) {
  const requestDetails = response.headers.get(REQUEST_DETAILS_HEADER);
  let error;

  try {
    error = await response.text();
  } catch (err) {
    error = err;
  }

  throw new Error(
    `${JSON.stringify(error)}; requestDetails: ${requestDetails}`
  );
}

export async function fetchMyUser(token: string): Promise<User> {
  const response = await fetch(`${Config.apiUrl}/users/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchMyContact(token: string): Promise<Contact> {
  const response = await fetch(`${Config.apiUrl}/users/me/contact`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchMyEmployerPayrollCycles(
  token: string
): Promise<PayrollCycle[]> {
  const response = await fetch(
    `${Config.apiUrl}/users/me/employer-payroll-cycles`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function qualifyLead({
  token,
  payload,
}: {
  token: string;
  payload: QualifyLeadPayload;
}): Promise<void> {
  const response = await fetch(`${Config.apiUrl}/users/me/qualify-lead`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }
}

export async function qualifyLeadRecover({
  token,
  payload,
}: {
  token: string;
  payload: QualifyLeadRecoverPayload;
}): Promise<void> {
  const response = await fetch(
    `${Config.apiUrl}/users/me/qualify-lead/recover`,
    {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }
}

export async function qualifyNewLead({
  token,
}: {
  token: string;
}): Promise<void> {
  const response = await fetch(`${Config.apiUrl}/users/me/qualify-new-lead`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }
}

export async function fetchDossiers(token: string): Promise<Dossier[]> {
  const response = await fetch(`${Config.apiUrl}/dossiers/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchDossiersForApproval(
  token: string
): Promise<Dossier[]> {
  const response = await fetch(`${Config.apiUrl}/dossiers/employer-approval`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchDossier(
  token: string,
  dossierId: string
): Promise<Dossier> {
  const response = await fetch(`${Config.apiUrl}/dossiers/me/${dossierId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchDossierStatus(
  token: string,
  dossierId: string
): Promise<DetailedDossierStatus> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/status`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchEmptyDossierStatus(
  token: string
): Promise<DetailedDossierStatus> {
  const response = await fetch(`${Config.apiUrl}/dossiers/empty-status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function approveDriver({
  token,
  dossierId,
  payload,
}: {
  token: string;
  dossierId: string;
  payload: ApproveDriverPayload;
}): Promise<boolean> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/${dossierId}/approve-driver`,
    {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function rejectDriver({
  token,
  dossierId,
}: {
  token: string;
  dossierId: string;
}): Promise<boolean> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/${dossierId}/reject-driver`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchPackagingAgreementPDF(
  token: string,
  dossierId: string,
  isApprover: boolean
): Promise<Blob> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/packaging-agreement-pdf${
      isApprover ? "?isApprover=true" : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.blob();
}

export async function signPackagingAgreement({
  token,
  dossierId,
  driverIp,
  driverDevice,
}: {
  token: string;
  dossierId: string;
  driverIp: string;
  driverDevice: string;
}): Promise<boolean> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/${dossierId}/sign-packaging-agreement`,
    {
      method: "post",
      body: JSON.stringify({ driverIp, driverDevice }),
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchBankAccount(
  token: string
): Promise<BankAccount | null> {
  const response = await fetch(`${Config.apiUrl}/users/me/direct-debit`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function createBankAccount({
  token,
  payload,
}: {
  token: string;
  payload: CreateBankAccountPayload;
}): Promise<boolean> {
  const response = await fetch(`${Config.apiUrl}/users/me/direct-debit`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchFinanceApplication(
  token: string,
  dossierId: string
): Promise<FinanceApplication | null> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/finance-application`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchSpousalDeclarationPDF(token: string): Promise<Blob> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/spousal-declaration-pdf`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.blob();
}

export async function fetchDirectDebitTermsPDF(token: string): Promise<Blob> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/direct-debit-terms-pdf`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.blob();
}

export async function fetchAssetsAndLiabilityTypes(
  token: string
): Promise<AssetAndLiabilityType[]> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/assets-and-liability-types`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

async function updateFinanceApplicationImages(
  token: string,
  dossierId: string,
  payload: FinanceApplicationFileUploads
): Promise<null> {
  const formData = new FormData();

  for (const key in payload) {
    const value = payload[key as keyof FinanceApplicationFileUploads];
    if (value) {
      formData.append(key, value);
    }
  }

  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/finance-application/images`,
    {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function updateFinanceApplication({
  token,
  dossierId,
  payload,
}: {
  token: string;
  dossierId: string;
  payload: UpdateFinanceApplicationPayload;
}): Promise<FinanceApplication | null> {
  const { fileUploads, ...financeApplication } = payload;

  if (fileUploads) {
    await updateFinanceApplicationImages(token, dossierId, fileUploads);
  }

  if (Object.keys(financeApplication).length > 0) {
    const response = await fetch(
      `${Config.apiUrl}/dossiers/me/${dossierId}/finance-application`,
      {
        method: "PATCH",
        body: JSON.stringify(financeApplication),
        headers: {
          Authorization: `Bearer ${token}`,
          ["Content-Type"]: "application/json",
        },
      }
    );

    if (!response.ok) {
      await handleErrorResponse(response);
    }

    return response.json();
  } else {
    return null;
  }
}

export async function requestEmployerAccountSetup({
  token,
  dossierId,
  payload,
}: {
  token: string;
  dossierId: string;
  payload: RequestEmployerSetupPayload;
}): Promise<string> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/request-account-setup`,
    {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function requestPackagingAgreement({
  token,
  dossierId,
}: {
  token: string;
  dossierId: string;
}): Promise<string> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/request-packaging-agreement`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchVehicleMakes(token: string): Promise<VehicleMake[]> {
  const response = await fetch(`${Config.apiUrl}/vehicles/makes`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchVehicleModels(
  token: string,
  makeId: string
): Promise<VehicleModel[]> {
  const queryString = querystring.stringify({ makeId });
  const path = attachQueryString(
    `${Config.apiUrl}/vehicles/models`,
    queryString
  );

  const response = await fetch(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchVehicleModelYears(
  token: string,
  modelId: string
): Promise<VehicleModelYear[]> {
  const queryString = querystring.stringify({ modelId });
  const path = attachQueryString(
    `${Config.apiUrl}/vehicles/model-years`,
    queryString
  );

  const response = await fetch(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchVehicleModelVariants(
  token: string,
  makeId: string,
  modelId: string,
  modelYear: string
): Promise<VehicleVariant[]> {
  const queryString = querystring.stringify({ makeId, modelId, modelYear });
  const path = attachQueryString(
    `${Config.apiUrl}/vehicles/variants`,
    queryString
  );

  const response = await fetch(path, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function createQuote({
  token,
  payload,
}: {
  token: string;
  payload: CreateQuotePayload;
}): Promise<string> {
  const response = await fetch(`${Config.apiUrl}/quotes`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function updateQuote({
  token,
  quoteId,
  payload,
}: {
  token: string;
  quoteId: string;
  payload: UpdateQuotePayload;
}): Promise<string> {
  const response = await fetch(`${Config.apiUrl}/quotes/me/${quoteId}`, {
    method: "PATCH",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function setPrimaryQuote({
  token,
  dossierId,
  quoteId,
}: {
  token: string;
  dossierId: string;
  quoteId: string;
}): Promise<boolean> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/set-primary-quote`,
    {
      method: "post",
      body: JSON.stringify({ quoteId }),
      headers: {
        Authorization: `Bearer ${token}`,
        ["Content-Type"]: "application/json",
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchQuote(
  token: string,
  quoteId: string
): Promise<CalculatedQuote> {
  const response = await fetch(`${Config.apiUrl}/quotes/me/${quoteId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchQuotes(
  token: string,
  dossierId: string
): Promise<Quote[]> {
  const response = await fetch(
    `${Config.apiUrl}/dossiers/me/${dossierId}/quotes`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchQuotePDF(
  token: string,
  quoteId: string
): Promise<Blob> {
  const response = await fetch(`${Config.apiUrl}/quotes/me/${quoteId}/pdf`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.blob();
}

export async function syncQuotes(
  token: string,
  syncEvent: QuotesSyncEvent
): Promise<void> {
  fetch(`${Config.apiUrl}/quotes/me/sync`, {
    method: "post",
    body: JSON.stringify({ syncEvent }),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });
}

export async function fetchVehicleOptions(
  token: string,
  variantId: string
): Promise<VehicleOptions> {
  const response = await fetch(
    `${Config.apiUrl}/vehicles/variants/${variantId}/options`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function getClientIP(): Promise<ClientIPData> {
  const response = await fetch("https://api.ipify.org?format=json");

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchFile(
  token: string,
  filePath: string,
  isImage?: boolean
): Promise<string> {
  const response = await fetch(
    `${Config.apiUrl}${filePath}${isImage ? "?isImage=true" : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  const blob = await response.blob();
  const imageUrl = URL.createObjectURL(blob);

  return imageUrl;
}

export async function fetchFaqAppLocations(
  token: string,
  pageId: APPage
): Promise<FaqAppLocation[]> {
  const response = await fetch(
    `${Config.apiUrl}/pages/${pageId}/faq-app-locations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchFaqs(
  token: string,
  appLocationId: string
): Promise<Faq[]> {
  const response = await fetch(
    `${Config.apiUrl}/app-locations/${appLocationId}/faqs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchFaq(token: string, faqId: string): Promise<Faq> {
  const response = await fetch(`${Config.apiUrl}/faqs/${faqId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function fetchFaqVote(
  token: string,
  faqId: string,
  appLocationId: string | null
): Promise<FaqVote | null> {
  const response = await fetch(
    `${Config.apiUrl}/faqs/${faqId}/vote${
      appLocationId ? `?appLocationId=${appLocationId}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function createFaqView({
  token,
  faqId,
  payload,
}: {
  token: string;
  faqId: string;
  payload: CreateFaqViewPayload;
}): Promise<void> {
  const response = await fetch(`${Config.apiUrl}/faqs/${faqId}/view`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}

export async function createFaqVote({
  token,
  faqId,
  payload,
}: {
  token: string;
  faqId: string;
  payload: CreateFaqVotePayload;
}): Promise<void> {
  const response = await fetch(`${Config.apiUrl}/faqs/${faqId}/vote`, {
    method: "post",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`,
      ["Content-Type"]: "application/json",
    },
  });

  if (!response.ok) {
    await handleErrorResponse(response);
  }

  return response.json();
}
