import { ContactRole } from "../types/User";

export const hasAccountRoles = (roles: ContactRole[]) => {
  return roles.some((role) => role.accountIsAuthorised);
};

export const filterAuthorisedSignatoryRoles = (roles: ContactRole[]) => {
  return roles.filter(
    (role) =>
      role.accountIsAuthorised &&
      (role.name === "Primary Authorised Signatory" ||
        role.name === "Authorised Signatory")
  );
};
