import "./index.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import * as Sentry from "@sentry/react";
import Keycloak from "keycloak-js";
import { KeycloakOnLoad } from "keycloak-js";
import { SnackbarProvider } from "notistack";
import { useCallback, useState } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";
import { Config } from "./config";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Sentry.init({
  dsn: "https://5760c4d51b1373dc3e8138a1ac825e91@o4507268658364416.ingest.us.sentry.io/4507268665835520",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost:8000",
    /^https:\/\/auto-pilot\.auto-ux\.io/,
    /^https:\/\/staging-auto-pilot\.auto-ux\.io/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

console.log("Keycloak Url:", Config.keycloakUrl);
console.log("API Url:", Config.apiUrl);

const initOptions = {
  url: Config.keycloakUrl,
  realm: "auto-pilot",
  clientId: "auto-pilot-portal",
  onLoad: "login-required" as KeycloakOnLoad,
  KeycloakResponseType: "code",
};

const keycloak = new Keycloak(initOptions);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0,
    },
  },
});

function AppWrapper() {
  const [, setTokenUpdateCount] = useState(0);

  const onUpdateToken = useCallback(() => {
    setTokenUpdateCount((value) => value + 1);
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={<></>}
      onTokens={onUpdateToken}
    >
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </LocalizationProvider>
            </ErrorBoundary>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </SnackbarProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

root.render(<AppWrapper></AppWrapper>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
