import { Navigate } from "react-router-dom";

import { User } from "../types/User";

const InitialSetupRoute = ({
  me,
  children,
}: {
  me: User;
  children: JSX.Element;
}) => {
  if (me && me.initialSetupCompleted) {
    return <Navigate to="/home" />;
  }

  return children;
};

export default InitialSetupRoute;
