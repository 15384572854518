import PhoneIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useKeycloak } from "@react-keycloak/web";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { fetchDossiers, qualifyNewLead } from "../api/autoPilot";
import { hasOpenDossier } from "../helpers/dossiers";
import { hasAccountRoles } from "../helpers/user";
import { User } from "../types/User";
import LoadingBackdrop from "./LoadingBackdrop";

function NavBar({ me }: { me: User }) {
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dossiersResponse = useQuery(["dossiers"], () =>
    me.crmContactId ? fetchDossiers(keycloak.token || "") : []
  );

  if (dossiersResponse.isError) {
    throw dossiersResponse.error;
  }

  const { mutateAsync, isLoading: isUpdating } = useMutation({
    mutationFn: qualifyNewLead,
    onError: () => {
      enqueueSnackbar(
        "There was an issue submitting the from, please try again.",
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("dossiers");
    },
  });

  const { pathname, search } = location;
  const isUserInfo = pathname === "/user";
  const isInitialQuote =
    !!pathname.match(/\/quote\/create/) && !!search.match("limitedFields=true");
  const hideMyCar = isUserInfo || isInitialQuote;
  const hideDirectDebit = isUserInfo;
  const isEmployer = me.contactRoles && hasAccountRoles(me.contactRoles);
  const canCreateDossier =
    dossiersResponse.isSuccess &&
    !hasOpenDossier(dossiersResponse.data) &&
    !isUserInfo;

  const handleNewLead = async () => {
    handleClose();
    await mutateAsync({ token: keycloak.token || "" });
    navigate("/dossiers?openFirst=true");
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar sx={{ height: 80, py: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/home">
              <Box
                component="img"
                sx={{ height: 42, display: "flex" }}
                src="/auto-ux.svg"
              ></Box>
            </Link>
          </Box>
          <Button
            href="tel:1800288689"
            color="secondary"
            variant="contained"
            disableElevation={true}
            sx={{
              alignSelf: "center",
              color: "onPrimary.main",
              p: 1.25,
              mr: 1,
              minWidth: "initial",
            }}
            size="small"
            aria-label="menu"
          >
            <PhoneIcon sx={{ fontSize: "1.25rem" }} />
          </Button>
          <IconButton
            sx={{ alignSelf: "center", mr: "-5px" }}
            size="small"
            color="primary"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            sx={{ ml: 2, mt: 1 }}
          >
            {isEmployer && (
              <Link to="/employer-home">
                <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                  Employer
                </MenuItem>
              </Link>
            )}
            {canCreateDossier && (
              <MenuItem sx={{ pr: 4 }} onClick={handleNewLead}>
                New Car
              </MenuItem>
            )}
            {!hideMyCar && (
              <Link to="/dossiers">
                <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                  My Car
                </MenuItem>
              </Link>
            )}
            {!hideDirectDebit && (
              <Link to="/user/direct-debit">
                <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                  Set My Bank Account
                </MenuItem>
              </Link>
            )}
            <Link to="https://www.auto-ux.io/privacy-policy/" target="_blank">
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Privacy
              </MenuItem>
            </Link>
            <Link to="https://www.auto-ux.io/contact/" target="_blank">
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Contact Us
              </MenuItem>
            </Link>
            <Box
              onClick={() =>
                keycloak.logout({ redirectUri: "https://auto-ux.io" })
              }
            >
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Sign Out
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      {isUpdating && <LoadingBackdrop />}
    </Box>
  );
}
export default NavBar;
