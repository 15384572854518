import CloseIcon from "@mui/icons-material/Close";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  fetchEmptyDossierStatus,
  qualifyLead,
  qualifyLeadRecover,
} from "../api/autoPilot";
import AutoPilotAlert from "../components/Alert";
import APAccordion from "../components/APAccordion";
import APAutoComplete, {
  AutoCompleteOption,
} from "../components/form/APAutoComplete";
import APForm from "../components/form/APForm";
import APTextField from "../components/form/APTextField";
import PageContainer from "../components/PageContainer";
import DossierStatusStepper from "../dossier/components/DossierStatusStepper";
import { leadSourceOptions } from "../types/LeadSourceOptions";
import { User } from "../types/User";

type Inputs = {
  mobile: string;
  employerName: string;
  source: AutoCompleteOption | null;
};

const UserInfo = ({ token, user }: { token: string; user: User }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const queryClient = useQueryClient();

  const dossierStatus = useQuery(["emptyDossierStatus"], () =>
    fetchEmptyDossierStatus(token || "")
  );

  if (dossierStatus.isError) {
    throw dossierStatus.error;
  }

  const onSuccess = () => {
    navigate("/dossiers");
  };

  const onError = () => {
    enqueueSnackbar(
      "There was an issue submitting the from, please try again.",
      {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      }
    );
  };

  const onSettled = () => {
    queryClient.invalidateQueries("user");
  };

  const { mutateAsync: mutateQualifyAsync, isLoading: isQualifyLoading } =
    useMutation({
      mutationFn: qualifyLead,
      onSuccess: onSuccess,
      onError: onError,
      onSettled: onSettled,
    });

  const { mutateAsync: mutateRecoverAsync, isLoading: isRecoverLoading } =
    useMutation({
      mutationFn: qualifyLeadRecover,
      onSuccess: onSuccess,
      onError: onError,
      onSettled: onSettled,
    });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // Check if user creation is in a broken state due to an error
    // in the previous submit attempt
    const leadIsQualified = !!user && !!user.leadIsQualified;
    const catchEDriverExists = !!user && !!user.catchEDriverId;
    const initalSetupCompleted = !!user && user.initialSetupCompleted;

    if (leadIsQualified && (!catchEDriverExists || !initalSetupCompleted)) {
      mutateRecoverAsync({
        token,
        payload: {
          catchEDriverExists,
          initalSetupCompleted,
        },
      });
    } else {
      mutateQualifyAsync({
        token,
        payload: {
          mobilePhone: data.mobile,
          companyName: data.employerName,
          leadSourceCode: data.source?.id || undefined,
        },
      });
    }
  };

  const isUpdating = isQualifyLoading || isRecoverLoading;

  return (
    <PageContainer loading={dossierStatus.isLoading} title="User Info">
      {dossierStatus.isSuccess && (
        <APForm
          onSubmit={handleSubmit(onSubmit)}
          submitText="Next"
          submitIcon={<PlayArrowOutlined />}
          isLoading={isUpdating}
          isError={Object.keys(errors).length > 0}
        >
          <APTextField<Inputs>
            name="mobile"
            label="Mobile"
            control={control}
            validations={{
              required: true,
              minLength: 10,
              maxLength: 12,
              formatValidation: /^[0-9\s()+-]+$/,
            }}
            errors={errors}
            defaultValue=""
          />
          <APTextField<Inputs>
            name="employerName"
            label="Employer Name"
            control={control}
            validations={{
              required: true,
              maxLength: 100,
            }}
            errors={errors}
            defaultValue=""
          />
          <APAutoComplete
            name="source"
            label="How did you hear about Auto-UX?"
            options={leadSourceOptions}
            control={control}
            validations={{}}
            errors={errors}
            defaultValue={null}
          />
          <APAccordion title="Your Progress" defaultExpanded>
            <Box sx={{ mt: 1, ml: 1 }}>
              <DossierStatusStepper
                dossierStatus={dossierStatus.data}
              ></DossierStatusStepper>
            </Box>
          </APAccordion>
          <Box>
            <AutoPilotAlert
              severity="info"
              message="Click next to build your quote!"
              asHTML={true}
            />
          </Box>
        </APForm>
      )}
    </PageContainer>
  );
};

export default UserInfo;
