import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APAccordion from "../../../components/APAccordion";
import APAutoComplete from "../../../components/form/APAutoComplete";
import APFileUpload from "../../../components/form/APFileUpload";
import APTextField from "../../../components/form/APTextField";
import { PayFrequencyToPeriodsPerYear } from "../../../helpers/calculations/finance";
import { toDollars } from "../../../helpers/currencies";
import {
  showEmploymentPreviousEmployment,
  showEmploymentTaxReturns,
} from "../../../helpers/financeApplication";
import { PayFrequency } from "../../../types/Dossier";
import {
  employmentTypeOptions,
  FinanceApplicationInputs,
  payFrequencyOptions,
} from "..";

const FinanceApplicationEmploymentForm = ({
  control,
  errors,
  watch,
  setValue,
  readOnly,
  highlightRequired,
}: {
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const watchYears = watch("currentEmploymentYearsAt");
  const watchSalaryPerPay = watch("annualSalaryPerPay");
  const watchPayFrequency = watch("annualSalaryPayFrequency");
  const watchNetMonthly = watch("netMonthlyPay");
  const watchEmploymentType = watch("currentEmploymentType");
  const showPreviousEmployment = showEmploymentPreviousEmployment(watchYears);
  const showTaxReturns = showEmploymentTaxReturns(watchEmploymentType);

  // If user has been at their current employment for less then
  // greater then two years, then clear previous employment fields
  useEffect(() => {
    if (!showPreviousEmployment) {
      setValue("previousEmployerName", "");
      setValue("previousJobTitle", "");
      setValue("previousEmployerContactName", "");
      setValue("previousEmployerContactPhone", "");
      setValue("previousEmploymentYearsAt", "");
      setValue("previousEmploymentMonthsAt", "");
    }
  }, [showPreviousEmployment]);

  // If tax returns are no longer shown then clear the fields
  useEffect(() => {
    if (!showTaxReturns) {
      setValue("lastTaxReturnImage", null);
      setValue("prevTaxReturnImage", null);
    }
  }, [showTaxReturns]);

  useEffect(() => {
    if (watchSalaryPerPay && watchPayFrequency) {
      const netMonthlyPay = Math.round(
        parseInt(watchSalaryPerPay) *
          (PayFrequencyToPeriodsPerYear(watchPayFrequency.id as PayFrequency) /
            12)
      );
      setValue("netMonthlyPay", `${netMonthlyPay}`);
    } else {
      setValue("netMonthlyPay", "");
    }
  }, [watchSalaryPerPay, watchPayFrequency]);

  return (
    <Stack spacing={2}>
      <APTextField<FinanceApplicationInputs>
        name="currentEmployerName"
        label="Current Employer"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APAutoComplete
        name="currentEmploymentType"
        label="Employment Type"
        options={employmentTypeOptions}
        control={control}
        validations={{}}
        errors={errors}
        defaultValue={null}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      {showTaxReturns && (
        <Stack spacing={2}>
          <APFileUpload
            name="lastTaxReturnImage"
            label="Last FY Personal Tax Return"
            control={control}
            errors={errors}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />

          <APFileUpload
            name="prevTaxReturnImage"
            label="Prev FY Personal Tax Return"
            control={control}
            errors={errors}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        </Stack>
      )}

      <APTextField<FinanceApplicationInputs>
        name="currentJobTitle"
        label="Job Title"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APTextField<FinanceApplicationInputs>
        name="currentEmployerContactName"
        label="Employer Contact Name"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        helperText="Employer contact that can verify your employment"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APTextField<FinanceApplicationInputs>
        name="currentEmployerContactPhone"
        label="Employer Contact Phone"
        control={control}
        validations={{
          minLength: 8,
          maxLength: 20,
          formatValidation: /^[0-9\s()+-]+$/,
        }}
        errors={errors}
        defaultValue=""
        placeholder="Phone"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", columnGap: 2 }}
      >
        <APTextField<FinanceApplicationInputs>
          name="currentEmploymentYearsAt"
          label="Current Employment Yrs"
          control={control}
          validations={{
            maxLength: 2,
            formatValidation: /^[0-9\s()+-]+$/,
          }}
          errors={errors}
          defaultValue=""
          showRequired={true}
          highlightOnEmpty={highlightRequired}
          readOnly={readOnly}
        />
        <APTextField<FinanceApplicationInputs>
          name="currentEmploymentMonthsAt"
          label="Current Employment Mths"
          control={control}
          validations={{
            maxLength: 2,
            formatValidation: /^([0-9]|10|11)$/,
          }}
          errors={errors}
          defaultValue=""
          readOnly={readOnly}
        />
      </Box>

      <APTextField<FinanceApplicationInputs>
        name="annualSalaryTotal"
        label="Annual Salary excl super"
        control={control}
        validations={{
          formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
        }}
        errors={errors}
        defaultValue={""}
        startAdornment="$"
        placeholder="xxxxxxx.xx"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
        formatNumber={true}
      />

      <APFileUpload
        name="latestPayslipImage"
        label="Latest Payslip"
        control={control}
        errors={errors}
        helperText="Issued within 60 days"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APFileUpload
        name="prevPayslipImage"
        label="Previous Payslip"
        control={control}
        errors={errors}
        helperText="Issued within 90 days"
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <Box
        sx={{ display: "flex", justifyContent: "space-between", columnGap: 2 }}
      >
        <Box sx={{ flex: 1 }}>
          <APTextField<FinanceApplicationInputs>
            name="annualSalaryPerPay"
            label="Salary After Tax Per Pay"
            control={control}
            validations={{
              formatValidation: /^[0-9]+(\.[0-9][0-9])?$/,
            }}
            errors={errors}
            defaultValue={""}
            startAdornment="$"
            placeholder="xxxxx.xx"
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
            formatNumber={true}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <APAutoComplete
            name="annualSalaryPayFrequency"
            label="Pay Frequency"
            options={payFrequencyOptions}
            control={control}
            validations={{}}
            errors={errors}
            defaultValue={null}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        </Box>
      </Box>

      {watchNetMonthly && (
        <Box>
          <Typography variant="bodyLarge">
            {toDollars(parseInt(watchNetMonthly), 0)} Net Monthly Pay
          </Typography>
        </Box>
      )}

      {showPreviousEmployment && (
        <Box sx={{ pt: 2 }}>
          <APAccordion title="Previous Employment" defaultExpanded={true}>
            <Stack spacing={2} sx={{ pt: 2 }}>
              <APTextField<FinanceApplicationInputs>
                name="previousEmployerName"
                label="Previous Employer"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APTextField<FinanceApplicationInputs>
                name="previousJobTitle"
                label="Previous Job Title"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APTextField<FinanceApplicationInputs>
                name="previousEmployerContactName"
                label="Prev Employer Contact Name"
                control={control}
                validations={{
                  maxLength: 100,
                }}
                errors={errors}
                defaultValue=""
                helperText="Employer contact that can verify your employment"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <APTextField<FinanceApplicationInputs>
                name="previousEmployerContactPhone"
                label="Prev Employer Contact Phone"
                control={control}
                validations={{
                  minLength: 8,
                  maxLength: 20,
                  formatValidation: /^[0-9\s()+-]+$/,
                }}
                errors={errors}
                defaultValue=""
                placeholder="Phone"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 2,
                }}
              >
                <APTextField<FinanceApplicationInputs>
                  name="previousEmploymentYearsAt"
                  label="Prev Employment Yrs"
                  control={control}
                  validations={{
                    maxLength: 2,
                    formatValidation: /^[0-9\s()+-]+$/,
                  }}
                  errors={errors}
                  defaultValue=""
                  showRequired={true}
                  highlightOnEmpty={highlightRequired}
                  readOnly={readOnly}
                />
                <APTextField<FinanceApplicationInputs>
                  name="previousEmploymentMonthsAt"
                  label="Prev Employment Mths"
                  control={control}
                  validations={{
                    maxLength: 2,
                    formatValidation: /^([0-9]|10|11)$/,
                  }}
                  errors={errors}
                  defaultValue=""
                  readOnly={readOnly}
                />
              </Box>
            </Stack>
          </APAccordion>
        </Box>
      )}
    </Stack>
  );
};

export default FinanceApplicationEmploymentForm;
