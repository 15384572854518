import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { fetchDossiers } from "../../api/autoPilot";
import PageContainer from "../../components/PageContainer";
import {
  filterAuthorisedSignatoryRoles,
  hasAccountRoles,
} from "../../helpers/user";
import { User } from "../../types/User";

const EmployerHome = ({ token, me }: { token: string; me: User }) => {
  const navigate = useNavigate();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const response = useQuery(["dossiers"], () => fetchDossiers(token || ""));

  if (response.isError) {
    throw response.error;
  }

  const authorisedSignatoryRoles = me.contactRoles
    ? filterAuthorisedSignatoryRoles(me.contactRoles)
    : [];

  useEffect(() => {
    if (me.contactRoles && hasAccountRoles(me.contactRoles)) {
      setNavigationCheck(true);
    } else {
      navigate("/dossiers", { replace: true });
    }
  });

  return (
    <PageContainer
      title="Employer Home"
      loading={response.isLoading || !navigationCheckComplete}
    >
      {response.isSuccess && (
        <Stack spacing={2}>
          <Typography variant="labelLarge">
            Manage your employees' Auto-UX Leases
          </Typography>

          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "surface.main",
              rowGap: 2,
              p: 2,
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="headlineMedium">My Car</Typography>
              {response.data.length > 0 && (
                <Button
                  size="large"
                  color="primary"
                  sx={{ color: "onPrimary.main" }}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => navigate("/dossiers")}
                >
                  My Car
                </Button>
              )}
              {response.data.length === 0 && (
                <a href="mailto:new.driver@auto-ux.io">
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<PlayArrowOutlinedIcon />}
                  >
                    New Car
                  </Button>
                </a>
              )}
            </Box>
          </Card>

          {authorisedSignatoryRoles.length > 0 && (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "surface.main",
                rowGap: 2,
                p: 2,
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="headlineMedium">Approve</Typography>
                <Button
                  size="large"
                  color="primary"
                  sx={{ color: "onPrimary.main" }}
                  variant="contained"
                  disableElevation={true}
                  onClick={() => navigate("/employer-approve-drivers")}
                >
                  Approve Drivers
                </Button>
              </Box>
            </Card>
          )}
        </Stack>
      )}
    </PageContainer>
  );
};

export default EmployerHome;
