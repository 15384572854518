import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {
  createFaqView,
  fetchDossier,
  fetchFaqAppLocations,
  fetchFaqs,
  fetchQuote,
  fetchQuotePDF,
  setPrimaryQuote,
  updateQuote,
  UpdateQuotePayload,
} from "../../api/autoPilot";
import PageContainer from "../../components/PageContainer";
import { buildQuoteFilename, downloadFile } from "../../helpers/download";
import { QuotePayPeriod } from "../../types/Quote";
import { VehicleVariantPhoto } from "../../types/Vehicles";
import CalculatedQuote from "../components/CalculatedQuote";

export type ViewQuoteSubmitAction = "edit" | "new" | "next" | "download";
const ViewQuote = ({ token }: { token: string }) => {
  const { quoteId } = useParams();
  const [carouselAppLocationId, setCarouselAppLocationId] = useState(
    null as null | string
  );

  const navigate = useNavigate();

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const queryClient = useQueryClient();

  const onErrorMutate = () => {
    enqueueSnackbar(
      "There was an issue submitting the from, please try again.",
      {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      }
    );
  };
  const onSettledMutate = () => {
    queryClient.invalidateQueries("quotes");
    queryClient.invalidateQueries(["quote", quoteId]);
  };

  const { mutateAsync, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateQuote,
    onError: onErrorMutate,
    onSettled: onSettledMutate,
  });

  const {
    mutateAsync: mutatePrimaryToggleAsync,
    isLoading: isLoadingPrimaryToggle,
  } = useMutation({
    mutationFn: setPrimaryQuote,
    onError: onErrorMutate,
    onSuccess: () => {
      enqueueSnackbar("Your primary quote has been updated.", {
        variant: "success",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    },
    onSettled: () => {
      if (quote.data) {
        queryClient.invalidateQueries(["dossier", quote.data.dossierId]);
      }
    },
  });

  const quote = useQuery(["quote", quoteId], () =>
    fetchQuote(token || "", quoteId)
  );

  const dossierId = quote.data?.dossierId;
  const dossier = useQuery({
    queryKey: ["dossier", dossierId],
    queryFn: () => fetchDossier(token || "", dossierId || ""),
    enabled: !!dossierId,
  });

  const pageId = "quote-view";
  const faqAppLocations = useQuery(["faq-app-locations", pageId], () =>
    fetchFaqAppLocations(token || "", pageId)
  );
  const carouselFaqs = useQuery({
    queryKey: ["carouselFaqs", carouselAppLocationId],
    queryFn: () => fetchFaqs(token || "", carouselAppLocationId || ""),
    enabled: !!carouselAppLocationId,
  });

  useEffect(() => {
    if (faqAppLocations.isSuccess) {
      const carouselApplocation = faqAppLocations.data.find(
        (location) =>
          location.appLocationIdentifier === "QuoteView-BelowLifeofLeaseSavings"
      );

      if (carouselApplocation) {
        setCarouselAppLocationId(carouselApplocation.id);
      }
    }
  }, [faqAppLocations.isSuccess]);

  if (quote.isError) {
    throw quote.error;
  }

  if (dossier.isError) {
    throw dossier.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  const sortVehicleImages = (
    images: VehicleVariantPhoto[],
    primaryImageCode: string
  ): VehicleVariantPhoto[] => {
    const imagesToSort = [...images];

    imagesToSort.sort((a, b) => {
      if (a.code === primaryImageCode) {
        return -1;
      } else if (b.code === primaryImageCode) {
        return 1;
      }
      return -1;
    });

    return imagesToSort;
  };

  const download = async () => {
    if (quote.isSuccess && dossier.isSuccess) {
      const filename = buildQuoteFilename(
        dossier.data.identity,
        quote.data.vehicle.make,
        quote.data.vehicle.model,
        `${quote.data.term}`
      );

      const pdf = await fetchQuotePDF(token, quoteId);
      downloadFile(pdf, filename);
    }
  };

  const onSubmit = async (
    name: string,
    payPeriod: QuotePayPeriod,
    submitAction: ViewQuoteSubmitAction
  ) => {
    if (quote.isSuccess && dossier.isSuccess) {
      const updatePayload: UpdateQuotePayload = {};

      if (name !== quote.data.name) {
        updatePayload.name = name;
      }

      if (payPeriod !== quote.data.payPeriod) {
        updatePayload.payPeriod = payPeriod;
      }

      if (Object.keys(updatePayload).length > 0) {
        await mutateAsync({
          token,
          quoteId,
          payload: updatePayload,
        });
      }

      switch (submitAction) {
        case "next":
          navigate(`/dossiers/${dossier.data.id}`);
          break;
        case "new":
          navigate(`/quote/${quoteId}/clone`);
          break;
        case "edit":
          navigate(`/quote/${quoteId}/edit`);
          break;
        case "download":
          download();
          break;
      }
    }
  };

  const onSetPrimary = async (quoteId: string) => {
    if (dossier.isSuccess) {
      mutatePrimaryToggleAsync({ token, quoteId, dossierId: dossier.data.id });
    }
  };

  const handleCarouselFaqClick = (faqId: string) => {
    const faq = carouselFaqs.isSuccess
      ? carouselFaqs.data.find((faq) => faq.id === faqId)
      : undefined;
    createFaqView({
      token,
      faqId: faqId,
      payload: {
        appLocationId: carouselAppLocationId || null,
        faqAppLocationId: faq?.faqApplocationId || null,
      },
    });
  };

  const isLoading =
    quote.isFetching || dossier.isFetching || faqAppLocations.isFetching;
  const isSuccess =
    quote.isSuccess && dossier.isSuccess && faqAppLocations.isSuccess;

  // Can create a quote if there is less then 5 quotes and the can create flag is true
  const canCreateQuote =
    dossier.isSuccess &&
    (dossier.data.quoteCount || 0) < 5 &&
    (dossier.data.opportunity?.canCreateQuote || false);

  return (
    <PageContainer loading={isLoading}>
      {isSuccess && (
        <CalculatedQuote
          id={quote.data.id}
          name={quote.data.name || ""}
          isLocked={quote.data.isLocked}
          isPrimary={dossier.data.opportunity?.primaryQuoteId === quote.data.id}
          vehicleMake={quote.data.vehicle.make}
          vehicleModel={quote.data.vehicle.model}
          vehicleYear={quote.data.vehicle.year}
          vehicleVariantDescription={quote.data.vehicle.variantLongName}
          vehicleImages={sortVehicleImages(
            quote.data.vehicle.images,
            quote.data.primaryImageCode
          ).map((image) => image.url)}
          payPeriod={quote.data.payPeriod}
          valuesPerPeriod={quote.data.valuesPerPeriod}
          runningCostsPA={quote.data.runningCostsPA}
          annualSalary={quote.data.annualSalary}
          annualKm={quote.data.annualKm}
          vehicleOnroadCosts={quote.data.vehicleOnroadCosts}
          vehicleResidualGross={quote.data.vehicleResidualGross}
          term={quote.data.term}
          businessUsage={quote.data.businessUsage}
          savings={quote.data.savings}
          savingsPerYear={quote.data.savingsPerYear}
          lifeOfLeaseSavings={quote.data.lifeOfLeaseSavings}
          canCreateQuote={canCreateQuote}
          isSubmitting={isLoadingUpdate}
          handleSubmit={onSubmit}
          handleSetPrimary={onSetPrimary}
          disablePrimaryToggle={isLoadingPrimaryToggle}
          faqAppLocations={faqAppLocations.data}
          carouselAppLocationId={carouselAppLocationId}
          carouselFaqs={
            carouselFaqs && carouselFaqs.isSuccess ? carouselFaqs.data : []
          }
          handleCarouselFaqClick={handleCarouselFaqClick}
        />
      )}
    </PageContainer>
  );
};

export default ViewQuote;
