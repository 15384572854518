import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { hasAccountRoles } from "../helpers/user";
import { User } from "../types/User";

const Home = ({ me }: { token: string; me: User }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (me.contactRoles && hasAccountRoles(me.contactRoles)) {
      navigate("/employer-home", {
        replace: true,
      });
    } else {
      navigate("/dossiers", {
        replace: true,
      });
    }
  });

  return <div></div>;
};

export default Home;
