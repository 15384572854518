import Box from "@mui/material/Box";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { fetchFaq } from "../../api/autoPilot";
import ModalContainer from "../../components/ModalContainer";
import FaqDetails from "../components/FaqDetails";

const ViewFaq = ({ token }: { token: string }) => {
  const { faqId } = useParams();

  if (!faqId) {
    // TODO: improve client side error messaging
    throw new Error("faq id param is misisng");
  }

  const faq = useQuery(["faqs", faqId], () => fetchFaq(token || "", faqId));

  if (faq && faq.isError) {
    throw faq.error;
  }

  const isLoading = faq.isLoading;
  const isSuccess = faq.isSuccess;

  return (
    <ModalContainer loading={isLoading}>
      {isSuccess && (
        <Box>
          {faq.data && (
            <FaqDetails token={token} faq={faq.data} appLocationId={null} />
          )}
        </Box>
      )}
    </ModalContainer>
  );
};

export default ViewFaq;
