import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {
  fetchDossier,
  fetchDossierStatus,
  fetchFinanceApplication,
  fetchMyContact,
  fetchQuotePDF,
  fetchQuotes,
  setPrimaryQuote,
  syncQuotes,
} from "../../api/autoPilot";
import AutoPilotAlert from "../../components/Alert";
import APAccordion from "../../components/APAccordion";
import BackButton from "../../components/buttons/BackButton";
import PageContainer from "../../components/PageContainer";
import TermsButton from "../../components/TermsButton";
import { buildQuoteFilename, downloadFile } from "../../helpers/download";
import { DEFAULT_ACCOUNT } from "../../types/Contact";
import { buildNextActionRoute, NextDriverAction } from "../../types/Dossier";
import { Quote } from "../../types/Quote";
import DossierStatusStepper from "../components/DossierStatusStepper";
import QuoteCard from "../components/QuoteCard";

const ViewDossier = ({ token }: { token: string }) => {
  const { dossierId } = useParams();
  const navigate = useNavigate();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [showNextActionModal, setShowNextActionModal] = useState(false);

  if (!dossierId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id param is misisng");
  }

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: setPrimaryQuote,
    onError: () => {
      enqueueSnackbar(
        "There was an issue submitting the from, please try again.",
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar("Your primary quote has been updated.", {
        variant: "success",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["dossier", dossierId]);
    },
  });

  const contact = useQuery(["contact"], () => fetchMyContact(token));

  const dossier = useQuery(["dossier", dossierId], () =>
    fetchDossier(token || "", dossierId)
  );
  const dossierStatus = useQuery(["dossierStatus", dossierId], () =>
    fetchDossierStatus(token || "", dossierId)
  );
  const quotes = useQuery(["quotes", dossierId], () =>
    fetchQuotes(token || "", dossierId)
  );

  const financeApplication = useQuery(["finance-application", dossierId], () =>
    fetchFinanceApplication(token, dossierId)
  );

  if (contact.isError) {
    throw contact.error;
  }

  if (dossier.isError) {
    throw dossier.error;
  }

  if (dossierStatus.isError) {
    throw dossierStatus.error;
  }

  if (quotes.isError) {
    throw quotes.error;
  }

  if (financeApplication.isError) {
    throw financeApplication.error;
  }

  const isLoading =
    contact.isLoading ||
    dossier.isLoading ||
    dossierStatus.isLoading ||
    quotes.isLoading ||
    financeApplication.isLoading;
  const isSuccess =
    contact.isSuccess &&
    dossier.isSuccess &&
    dossierStatus.isSuccess &&
    quotes.isSuccess &&
    financeApplication.isSuccess;
  const primaryQuoteId = dossier.data?.opportunity?.primaryQuoteId;

  useEffect(() => {
    // If there are no quotes then navigate to create quote page
    if (isSuccess) {
      if (dossier.data.quoteCount === 0) {
        navigate(`/dossiers/${dossierId}/quote/create?limitedFields=true`, {
          replace: true,
        });
      } else {
        setNavigationCheck(true);
      }
    }
  }, [isSuccess]);

  const onDownload = async (quoteId: string) => {
    if (quotes.isSuccess && dossier.isSuccess) {
      const quote = quotes.data.find((quote) => quote.crmQuoteId === quoteId);
      const filename = quote
        ? buildQuoteFilename(
            dossier.data.identity,
            quote.vehicleMake,
            quote.vehicleModel,
            `${quote.termMonths}`
          )
        : `${dossier.data.identity}.pdf`;
      const pdf = await fetchQuotePDF(token, quoteId);
      downloadFile(pdf, filename);
    }
  };

  const onClone = async (quoteId: string) => {
    navigate(`/quote/${quoteId}/clone`);
  };

  const onEdit = async (quoteId: string) => {
    navigate(`/quote/${quoteId}/edit`);
  };

  const onSetPrimary = async (quoteId: string) => {
    mutateAsync({ token, quoteId, dossierId });
  };

  const onNext = async (nextAction: NextDriverAction) => {
    const route = buildNextActionRoute(dossierId, nextAction);
    navigate(route);
  };

  const onNew = async () => {
    if (quotes.isSuccess && dossier.isSuccess) {
      const quoteId = primaryQuoteId || quotes.data[0].crmQuoteId;

      navigate(`/quote/${quoteId}/clone`);
    }
  };

  const onFinanceApplication = async () => {
    navigate(`/dossiers/${dossierId}/finance-application`);
  };

  const onStatusItemClick = () => {
    setShowNextActionModal(true);
  };

  const onCloseNextActionModal = () => {
    setShowNextActionModal(false);
  };

  const hasLockedQuote =
    quotes.isSuccess && quotes.data.some((quote) => quote.isLocked);
  const canCreateQuote =
    dossier.isSuccess && dossier.data.opportunity?.canCreateQuote;

  const renderQuoteCard = (quote: Quote) => {
    return (
      <QuoteCard
        id={quote.crmQuoteId}
        key={quote.crmQuoteId}
        name={quote.name}
        isLocked={quote.isLocked}
        isPrimary={quote.crmQuoteId === primaryQuoteId}
        vehicleMake={quote.vehicleMake}
        vehicleModel={quote.vehicleModel}
        vehicleYear={quote.vehicleYear}
        vehicleImage={quote.vehicleImage}
        vehicleOnroadCosts={quote.vehicleOnroadCostsFormatted}
        annualKm={quote.budgetedAnnualKmFormatted}
        term={quote.termMonths}
        startDate={quote.startDate}
        endDate={quote.endDate}
        savings={quote.savings}
        weeklyNetCosts={quote.weeklyNetCostsFormatted}
        handleDownload={onDownload}
        handleClone={onClone}
        handleEdit={onEdit}
        handleNext={() =>
          dossierStatus.isSuccess && onNext(dossierStatus.data.nextDriverAction)
        }
        handleSetPrimary={onSetPrimary}
        disablePrimaryToggle={isLoadingUpdate || hasLockedQuote}
        hideClone={!!quotes.data && quotes.data.length >= 5}
      />
    );
  };

  const sortQuotes = (quotes: Quote[]): Quote[] => {
    const quotesToSort = [...quotes];

    quotesToSort.sort((a, b) => {
      if (a.crmQuoteId === primaryQuoteId) {
        return -1;
      } else if (b.crmQuoteId === primaryQuoteId) {
        return 1;
      } else if (a.updatedAt > b.updatedAt) {
        return -1;
      }
      if (a.updatedAt < b.updatedAt) {
        return 1;
      }
      return 0;
    });

    return quotesToSort;
  };

  const onRefresh = async () => {
    syncQuotes(token, "quotes-user-trigger");
    navigate(0);
  };

  return (
    <PageContainer loading={isLoading || !navigationCheckComplete}>
      {isSuccess && navigationCheckComplete && (
        <Stack spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography variant="titleSmall" color="onSurface.main">
                {dossier.data.identity} - {contact.data.firstName}{" "}
                {contact.data.lastName}
              </Typography>
              {contact.data.parentAccountId !== DEFAULT_ACCOUNT && (
                <Typography variant="titleSmall" color="onSurface.main">
                  {contact.data.parentAccountEmployerName}
                </Typography>
              )}
            </Box>
            <IconButton
              size="large"
              aria-label="download-quote"
              color="onSurface"
              onClick={() => onRefresh()}
              sx={{ p: 0 }}
            >
              <AutorenewIcon />
            </IconButton>
          </Box>
          <Stack spacing={2}>
            {sortQuotes(quotes.data).map(renderQuoteCard)}
          </Stack>

          <TermsButton termsId="quote" />

          {canCreateQuote && dossier.data.status !== "Active" && (
            <Box>
              {quotes.data.length < 5 ? (
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  startIcon={<AddIcon />}
                  sx={{ color: "onPrimary.main" }}
                  onClick={() => onNew()}
                >
                  New
                </Button>
              ) : (
                <Typography variant="titleMedium">
                  *Max quotes reached, contact Auto-UX if you require more.
                </Typography>
              )}
            </Box>
          )}

          <Box>
            <APAccordion title="What is Next?" defaultExpanded>
              <Box sx={{ mt: 1, ml: 1 }}>
                <DossierStatusStepper
                  dossierStatus={dossierStatus.data}
                  onItemClick={
                    dossier.data.noteToDriver ? onStatusItemClick : undefined
                  }
                ></DossierStatusStepper>
              </Box>
            </APAccordion>
            <Modal open={showNextActionModal} onClose={onCloseNextActionModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Paper
                  sx={{
                    py: 2,
                    px: 2,
                    overflowY: "auto",
                    maxWidth: "900px",
                    maxHeight: "95vh",
                    width: "95vw",
                  }}
                >
                  {dossier.data.noteToDriver && (
                    <Box>
                      <AutoPilotAlert
                        severity="info"
                        message={dossier.data.noteToDriver}
                        asHTML={true}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      pt: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      size="large"
                      color="primary"
                      variant="outlined"
                      disableElevation={true}
                      onClick={() => onCloseNextActionModal()}
                    >
                      Close
                    </Button>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      disableElevation={true}
                      startIcon={<PlayArrowOutlinedIcon />}
                      onClick={() =>
                        onNext(dossierStatus.data.nextDriverAction)
                      }
                    >
                      Next
                    </Button>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {dossier.data.noteToDriver && (
            <Box>
              <AutoPilotAlert
                severity="info"
                message={dossier.data.noteToDriver}
                asHTML={true}
              />
            </Box>
          )}

          <Box
            sx={{
              pt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <BackButton />
              {!financeApplication.data?.isHidden && (
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  sx={{ color: "onPrimary.main" }}
                  onClick={() => onFinanceApplication()}
                >
                  Finance App
                </Button>
              )}
            </Stack>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              disableElevation={true}
              startIcon={<PlayArrowOutlinedIcon />}
              onClick={() => onNext(dossierStatus.data.nextDriverAction)}
            >
              Next
            </Button>
          </Box>
        </Stack>
      )}
    </PageContainer>
  );
};

export default ViewDossier;
