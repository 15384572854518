import CloseIcon from "@mui/icons-material/Close";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { fetchDossiers, qualifyNewLead } from "../../api/autoPilot";
import PageContainer from "../../components/PageContainer";
import { hasOpenDossier } from "../../helpers/dossiers";
import { Dossier } from "../../types/Dossier";
import DossierCard from "../components/DossierCard";

const dossierStatusOrder = {
  New: 1,
  Active: 2,
  Closed: 3,
  Closing: 4,
  Cancelled: 5,
};

const DossierList = ({ token }: { token: string }) => {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const response = useQuery(["dossiers"], () => fetchDossiers(token || ""));
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const openFirstDossier =
    !!searchParams.get("openFirst") && searchParams.get("openFirst") === "true";

  if (response.isError) {
    throw response.error;
  }

  const { mutateAsync, isLoading: isUpdating } = useMutation({
    mutationFn: qualifyNewLead,
    onError: () => {
      enqueueSnackbar(
        "There was an issue submitting the from, please try again.",
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("dossiers");
    },
  });

  const filterDossiers = (dossiers: Dossier[]): Dossier[] => {
    return dossiers.filter(
      (dossier) => dossier.status && dossier.status !== "Cancelled"
    );
  };

  const sortDossiers = (dossiers: Dossier[]): Dossier[] => {
    const dossiersToSort = [...dossiers];
    dossiersToSort.sort((a, b) => {
      const aStatusOrder = dossierStatusOrder[a.status] || 10;
      const bStatusOrder = dossierStatusOrder[b.status] || 10;

      return aStatusOrder - bStatusOrder;
    });

    return dossiersToSort;
  };

  useEffect(() => {
    // If there is only one dossier then navigate to it
    if (response.isSuccess) {
      const dossiers = filterDossiers(response.data);
      if (dossiers.length === 1 || openFirstDossier) {
        const dossier = dossiers[0];
        navigate(`/dossiers/${dossier.id}`, { replace: true });
      } else {
        setNavigationCheck(true);
      }
    }
  }, [response.data]);

  const renderCard = (dossier: Dossier) => {
    return (
      <DossierCard
        id={dossier.id}
        key={dossier.id}
        status={dossier.status}
        identity={dossier.identity}
        startDate={dossier.startDate}
        endDate={dossier.endDate}
        vehicleMake={dossier.vehicleMakeName}
        vehicleModel={dossier.vehicleModelName}
        vehicleYear={dossier.vehicleModelyear}
        vehicleImage={dossier.vehicleVariantImage || "/photo-placeholder.png"}
      />
    );
  };

  const onNewQuote = () => {
    mutateAsync({ token });
    navigate("/dossiers?openFirst=true", { replace: true });
  };

  const canCreateDossier = response.isSuccess && !hasOpenDossier(response.data);
  return (
    <PageContainer
      loading={response.isFetching || isUpdating || !navigationCheckComplete}
      title="Dossiers"
    >
      {response.isSuccess && navigationCheckComplete && (
        <Stack spacing={2}>
          {canCreateDossier && (
            <Box>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                disableElevation={true}
                startIcon={<DriveEtaOutlinedIcon />}
                sx={{ color: "onPrimary.main" }}
                onClick={onNewQuote}
              >
                New Quote
              </Button>
            </Box>
          )}
          {sortDossiers(filterDossiers(response.data)).map(renderCard)}
        </Stack>
      )}
    </PageContainer>
  );
};

export default DossierList;
