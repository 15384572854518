import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { addModalToHref } from "../../helpers/routes";
import { Faq } from "../../types/Faq";

const FaqListCard = ({
  faq,
  appLocationId,
  highlightText,
  flat,
  handleFaqClick,
}: {
  faq: Faq;
  appLocationId: string;
  highlightText?: boolean;
  flat?: boolean;
  handleFaqClick?: (faqId: string) => void;
}) => {
  const linkTo = addModalToHref(
    `/modal/app-location/${appLocationId}/faq/${faq.id}`,
    window.location.href
  );

  return (
    <Card
      sx={{
        display: "flex",
        flex: 1,
        height: "inherit",
        border: flat ? "none" : null,
      }}
      variant="outlined"
    >
      <CardActionArea
        color="primary"
        sx={{
          display: "flex",
          backgroundColor: "surface.main",
          py: 1,
          px: 2,
        }}
        component={Link}
        to={linkTo}
        onClick={() => handleFaqClick && handleFaqClick(faq.id)}
      >
        <Box>
          <Typography
            variant="titleLarge"
            align="center"
            color={highlightText ? "secondary" : "initial"}
          >
            {faq.question}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default FaqListCard;
