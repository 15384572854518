import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import {
  fetchFile,
  fetchQuote,
  fetchVehicleOptions,
  updateQuote,
} from "../../api/autoPilot";
import PageContainer from "../../components/PageContainer";
import { downloadFileFromUrl } from "../../helpers/download";
import QuotePersonaliser from "../components/QuotePersonaliser";

const PersonaliseQuote = ({ token }: { token: string }) => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm();

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const quote = useQuery(["quote", quoteId], () =>
    fetchQuote(token || "", quoteId)
  );

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading: isSubmitting } = useMutation({
    mutationFn: updateQuote,
    onError: () => {
      enqueueSnackbar(
        "There was an issue submitting the from, please try again.",
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("quotes");
      queryClient.invalidateQueries(["quote", quoteId]);
    },
  });

  const variantId = quote.data?.vehicle.variantId;
  const vehicleOptions = useQuery({
    queryKey: ["vehicle-options", variantId],
    queryFn: () => fetchVehicleOptions(token || "", variantId || ""),
    enabled: !!variantId,
  });

  if (quote.isError) {
    throw quote.error;
  }

  if (vehicleOptions.isError) {
    throw vehicleOptions.error;
  }

  useEffect(() => {
    if (quote?.isSuccess) {
      quote.data.catchEEquipmentIds.forEach((id) => {
        setValue(`options.${id}`, true);
      });
    }
  }, [quote.isSuccess]);

  const isLoading = vehicleOptions.isFetching || quote.isFetching;
  const isSuccess = vehicleOptions.isSuccess && quote.isSuccess;

  const onSubmit: SubmitHandler<FieldValues> = async ({ options }) => {
    if (quote.isSuccess) {
      const optionalEquipment: string[] = [];
      const oldEquipment = quote.data?.catchEEquipmentIds;

      if (options) {
        Object.keys(options).forEach((optionId) => {
          if (options[optionId]) {
            optionalEquipment.push(optionId);
          }
        });
      }

      const hasChanged =
        optionalEquipment.length !== oldEquipment.length ||
        !optionalEquipment.every(
          (equipmentId) => oldEquipment.indexOf(equipmentId) !== 1
        );

      if (hasChanged) {
        await mutateAsync({
          token,
          quoteId: quoteId,
          payload: { optionalEquipment },
        });
      }

      navigate(`/quote/${quoteId}`);
    }
  };

  const onEdit = async (values: FieldValues) => {
    await onSubmit(values);
    navigate(`/quote/${quoteId}/edit`);
  };

  const onOptionDownload = async (url: string, filename: string) => {
    const pdfSrc = await fetchFile(token, url);
    downloadFileFromUrl(pdfSrc, filename);
  };

  // TODO: sort the vehicle images
  return (
    <PageContainer title="Vehicle Personalisation" loading={isLoading}>
      {isSuccess && (
        <QuotePersonaliser
          token={token}
          vehicleMake={quote.data.vehicle.make}
          vehicleModel={quote.data.vehicle.model}
          vehicleYear={quote.data.vehicle.year}
          vehicleVariantDescription={quote.data.vehicle.variantLongName}
          vehicleImages={quote.data.vehicle.images.map(
            (vehicle) => vehicle.url
          )}
          basePrice={quote.data.listPrice}
          series={quote.data.vehicle.series}
          body={quote.data.vehicle.body}
          seats={quote.data.vehicle.seats}
          transmission={quote.data.vehicle.transmission}
          drive={quote.data.vehicle.driveType}
          fuelType={quote.data.vehicle.fuelType}
          releaseDate={quote.data.vehicle.year}
          co2Emissions={quote.data.vehicle.co2Emissions}
          ancapRating={quote.data.vehicle.ancapRating}
          vehicleOptions={vehicleOptions.data}
          standardFeatures={quote.data.vehicle.standardEquipment}
          savingsPercentage={quote.data.savings}
          term={quote.data.term}
          handleEdit={handleSubmit(onEdit)}
          control={control}
          handleOptionDownload={onOptionDownload}
          handleSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
        />
      )}
    </PageContainer>
  );
};

export default PersonaliseQuote;
