import { FieldValues, RegisterOptions } from "react-hook-form";

export type ValidationOptions = {
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  formatValidation?: RegExp;
};

export type ControlRules = Omit<
  RegisterOptions<FieldValues, string>,
  "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
>;

export const requiredValidation = (): string => {
  return `This field is required`;
};

export const maxLengthValidation = (length: number): string => {
  return `This field should not exceed ${length} characters`;
};

export const minLengthValidation = (length: number): string => {
  return `This field should be at least ${length} characters`;
};

export const formatValidation = (): string => {
  return `Invalid format for this field`;
};

export const validationOptionsToControlRules = (
  fieldLabel: string,
  options: ValidationOptions
): ControlRules => {
  const rules: ControlRules = {};

  if (options.required) {
    rules.required = requiredValidation();
  }
  if (options.maxLength) {
    rules.maxLength = {
      value: options.maxLength,
      message: maxLengthValidation(options.maxLength),
    };
  }
  if (options.minLength) {
    rules.minLength = {
      value: options.minLength,
      message: minLengthValidation(options.minLength),
    };
  }
  if (options.formatValidation) {
    rules.pattern = {
      value: options.formatValidation,
      message: formatValidation(),
    };
  }

  return rules;
};
