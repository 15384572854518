import "./App.css";

import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { fetchMyUser } from "./api/autoPilot";
import APModalStack from "./components/APModalStack";
import InitialSetupRoute from "./components/InitialSetupRoute";
import LoadingBackdrop from "./components/LoadingBackdrop";
import NavBar from "./components/NavBar";
import PageViewTracker from "./components/PageViewTracker";
import ProtectedRoute from "./components/ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import ActionConfirmation from "./dossier/actionConfirmation";
import DirectDebit from "./dossier/directDebit";
import DossierList from "./dossier/dossierList";
import EmployerSetup from "./dossier/employerSetup";
import FinanceApplication from "./dossier/financeApplication";
import PackagingAgreement from "./dossier/packagingAgreement";
import ViewDossier from "./dossier/viewDossier";
import ApproveDrivers from "./employer/approveDrivers";
import EmployerHome from "./employer/home";
import ViewFaq from "./faq/viewFaq";
import ViewAppLocationFaqs from "./faq/viewFaqList";
import Home from "./home";
import CreateQuote, { QuoteCreateAction } from "./quote/createQuote";
import PersonaliseQuote from "./quote/personaliseQuote";
import ViewQuote from "./quote/viewQuote";
import UserInfo from "./userInfo";

function App() {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [modalStack, setModalStack] = useState([] as string[]);
  const modalPath = searchParams.get("modal");

  if (modalPath) {
    // If navigating to previous modal,then pop modal stack
    if (location.state?.popModalStack) {
      location.state.popModalStack = null;
      const newStack = modalStack.slice(0, modalStack.length - 1);

      setModalStack(newStack);
    } else {
      // Else we add to stack if it is a new modal path
      const lastModalPath =
        (modalStack.length > 0 && modalStack[modalStack.length - 1]) || null;

      if (!lastModalPath || lastModalPath !== modalPath) {
        setModalStack([...modalStack, modalPath]);
      }
    }
  } else if (modalStack.length > 0) {
    setModalStack([]);
  }

  useEffect(() => {
    const handleFocus = () => {
      keycloak.updateToken(0);
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const response = useQuery(["user"], () => fetchMyUser(keycloak.token || ""));

  if (response.isError) {
    throw response.error;
  }

  return (
    <>
      {response.isSuccess && keycloak.token && (
        <React.Fragment>
          <NavBar me={response.data} />
          <PageViewTracker />
          <ScrollToTop />
          <Routes>
            <Route
              path="/dossiers"
              element={
                <ProtectedRoute me={response.data}>
                  <DossierList token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId"
              element={
                <ProtectedRoute me={response.data}>
                  <ViewDossier token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/quote/create"
              element={
                <ProtectedRoute me={response.data}>
                  <CreateQuote
                    token={keycloak.token}
                    action={QuoteCreateAction.Create}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/employer-setup"
              element={
                <ProtectedRoute me={response.data}>
                  <EmployerSetup token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/finance-application"
              element={
                <ProtectedRoute me={response.data}>
                  <FinanceApplication token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/packaging-agreement"
              element={
                <ProtectedRoute me={response.data}>
                  <PackagingAgreement token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/direct-debit"
              element={
                <ProtectedRoute me={response.data}>
                  <DirectDebit token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dossiers/:dossierId/action-confirmation/:eventId"
              element={
                <ProtectedRoute me={response.data}>
                  <ActionConfirmation token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/employer-home"
              element={
                <ProtectedRoute me={response.data}>
                  <EmployerHome token={keycloak.token} me={response.data} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/employer-approve-drivers"
              element={
                <ProtectedRoute me={response.data}>
                  <ApproveDrivers token={keycloak.token} me={response.data} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/quote/:quoteId"
              element={
                <ProtectedRoute me={response.data}>
                  <ViewQuote token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/quote/:quoteId/personalise"
              element={
                <ProtectedRoute me={response.data}>
                  <PersonaliseQuote token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/quote/:quoteId/edit"
              element={
                <ProtectedRoute me={response.data}>
                  <CreateQuote
                    token={keycloak.token}
                    action={QuoteCreateAction.Edit}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/quote/:quoteId/clone"
              element={
                <ProtectedRoute me={response.data}>
                  <CreateQuote
                    token={keycloak.token}
                    action={QuoteCreateAction.Clone}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user"
              element={
                <InitialSetupRoute me={response.data}>
                  <UserInfo token={keycloak.token} user={response.data} />
                </InitialSetupRoute>
              }
            />
            <Route
              path="/user/direct-debit"
              element={
                <ProtectedRoute me={response.data}>
                  <DirectDebit token={keycloak.token} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home"
              element={
                <ProtectedRoute me={response.data}>
                  <Home token={keycloak.token} me={response.data} />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
          {modalStack.length > 0 && (
            <Routes
              location={{
                ...location,
                pathname: modalStack[modalStack.length - 1],
              }}
            >
              <Route
                element={
                  <APModalStack
                    modalStack={modalStack}
                    appLocation={location}
                  />
                }
              >
                <Route
                  path="/modal/faq/:faqId"
                  element={<ViewFaq token={keycloak.token} />}
                />
                <Route
                  path="/modal/app-location/:appLocationId"
                  element={<ViewAppLocationFaqs token={keycloak.token} />}
                />

                <Route
                  path="/modal/app-location/:appLocationId/faq/:faqId"
                  element={<ViewAppLocationFaqs token={keycloak.token} />}
                />
              </Route>
            </Routes>
          )}
        </React.Fragment>
      )}
      {response.isLoading && <LoadingBackdrop />}
    </>
  );
}

export default App;
