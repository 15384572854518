import { Navigate } from "react-router-dom";

import { User } from "../types/User";

const ProtectedRoute = ({
  me,
  children,
}: {
  me: User;
  children: JSX.Element;
}) => {
  if (!me || !me.initialSetupCompleted) {
    return <Navigate to="/user" />;
  }

  return children;
};

export default ProtectedRoute;
