import { ToggleButtonOption } from "../components/form/APFormToggleGroup";
import { State } from "./State";
import { VehicleVariantPhoto } from "./Vehicles";

export type Quote = {
  crmQuoteId: string;
  catchEQuoteId: string;
  crmAccountId: string | null;
  crmContactId: string;
  vehicleRedbookKey: string;
  name: string;
  description: string | null;
  isLocked: boolean;
  budgetedAnnualKm: number;
  budgetedAnnualKmFormatted: string;
  businessUsage: number;
  businessUsageFormatted: string;
  annualGrossSalary: number;
  annualGrossSalaryFormatted: string;
  financeBrokerageRevenue: number;
  financeBrokerageRevenueFormatted: string;
  vehicleSourcingRevenue: number;
  vehicleSourcingRevenueFormatted: string;
  productRevenue: number;
  productRevenueFormatted: string;
  totalRevenue: number;
  totalRevenueFormatted: string;
  vehicleOnroadCosts: number;
  vehicleOnroadCostsFormatted: string;
  totalAmountFinanced: number;
  totalAmountFinancedFormatted: string;
  savings: number;
  savingsFormatted: string;
  weeklyNetCosts: number;
  weeklyNetCostsFormatted: string;
  startDate: string | null;
  endDate: string | null;
  termMonths: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleVariant: string;
  vehicleState: number;
  vehicleStateFormatted: string;
  vehicleImage: string | null;
  updatedAt: string;
};

export const payCyclePeriodOptions: ToggleButtonOption[] = [
  { label: "Weekly", id: "weekly" },
  { label: "Fortnight", id: "fortnight" },
  { label: "Monthly", id: "monthly" },
  { label: "Annual", id: "annual" },
];

export type QuotePayPeriod = "weekly" | "fortnight" | "monthly" | "annual";
export type QuotesSyncEvent =
  | "quotes-system-trigger"
  | "quotes-user-trigger"
  | "quotes-admin-trigger";

export type QuoteInclusions = {
  finance: number;
  servicing: number;
  tyres: number;
  fuel: number;
  rego: number;
  insurance: number;
  managementFees: number;
  luxuryCharge: number;
  gst: number;
};

export type QuotePeriodValues = {
  inclusions: QuoteInclusions;
  packageValue: number;
  netCost: number;
};

export type CalculatedQuote = {
  id: string;
  dossierId: string;
  name: string;
  isLocked: boolean;
  vehicle: {
    make: string;
    makeId: string;
    model: string;
    modelId: string;
    year: string;
    description: string;
    variantId: string;
    series: string;
    body: string;
    seats: number;
    transmission: string;
    driveType: string;
    variantShortName: string;
    variantLongName: string;
    fuelType: string;
    co2Emissions: number;
    ancapRating: number;
    images: VehicleVariantPhoto[];
    standardEquipment: string[];
  };
  state: State;
  primaryImageCode: string;
  valuesPerPeriod: {
    weekly: QuotePeriodValues;
    fortnight: QuotePeriodValues;
    monthly: QuotePeriodValues;
    annual: QuotePeriodValues;
  };
  payPeriod: QuotePayPeriod;
  runningCostsPA: number;
  annualSalary: number;
  annualKm: number;
  vehicleOnroadCosts: number;
  vehicleResidualGross: number;
  term: number;
  businessUsage: number;
  savings: number;
  savingsPerYear: number;
  lifeOfLeaseSavings: number;
  listPrice: number;
  catchEEquipmentIds: string[];
};
